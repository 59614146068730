import React, { useState } from 'react'
import './index.scss'
import { graphql, Link } from 'gatsby'
import Jumbotron from 'react-bootstrap/Jumbotron'
import Container from 'react-bootstrap/Container'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import Layout from '../../components/Layout'
import PreviewCompatibleImage from '../../components/PreviewCompatibleImage'
import SEO from '../../components/SEO'
import { settingShopComingSoon } from '../../config'

function encode(data) {
    return Object.keys(data)
        .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
        .join('&')
}

const ShopPage = ({ data, location }) => {
    const [validated, setValidated] = useState(false)
    const [formData, setFormData] = useState({})
    const [signedUp, setSignedUp] = useState(false)

    const { allProduct: { products } } = data

    const handleChange = e => {
        const targetName = e.target.name
        const targetValue = e.target.value
        setFormData(prevFormData => ({
            ...prevFormData,
            [targetName]: targetValue
        }))
    }

    const handleSubmit = e => {
        e.preventDefault()
        e.stopPropagation()
        const form = e.currentTarget

        if (form.checkValidity() === true) {
            fetch('/', {
                method: 'POST',
                headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
                body: encode({
                    'form-name': form.getAttribute('name'),
                    ...formData,
                }),
            })
                .then(() => setSignedUp(true))
                .catch(error => alert(error))
        }
        setValidated(true)
    }

    return (
        <Layout location={location}>
            <SEO
                title='Shop'
                description='Bestelle Tassen oder T-Shirts mit deinem Tierportrait im TIERGETREU Shop.'
            />
            <Container className='products-page'>
                <Jumbotron className='mb-0 bg-white'>
                    <h1 className='text-tint'>Shop</h1>
                        {/* TODO: Remove this code-separation once `settingShopComingSoon` becomes obsolete */}
                        {settingShopComingSoon ? (
                        <React.Fragment>
                            <p className='mb-5'>
                                In Kürze bieten wir dir hier Produkte wie Tassen oder T-Shirts an, die mit dem Portrait
                                deines Lieblingsvierbeiners bedruckt sind.
                            </p>
                            <Jumbotron className='jumbotron-inner'>
                                <h5 className='mb-3'>Benachrichtigen lassen</h5>
                                <div className='animation-container'>
                                    {signedUp ? (
                                        <React.Fragment>
                                            <p className='mb-4'>
                                                Vielen Dank für deine Anmeldung!
                                            </p>
                                        </React.Fragment>
                                    ) : (
                                            <React.Fragment>
                                                <p className='mb-4'>
                                                    Wir benachrichtigen Dich sobald Du bei uns individuell bedruckte Produkte
                                                    wie Tassen und T-Shirts bestellen kannst!
                                                </p>
                                                <Form
                                                    noValidate
                                                    validated={validated}
                                                    name='notify-products'
                                                    method='post'
                                                    data-netlify='true'
                                                    data-netlify-honeypot='bot-field'
                                                    onSubmit={handleSubmit}
                                                >
                                                    {/* The `form-name` hidden field is required to support form submissions without JavaScript */}
                                                    <input type='hidden' name='form-name' value='notify-products' />
                                                    <div hidden>
                                                        <label>
                                                            Don’t fill this out:{' '}
                                                            <input name='bot-field' onChange={handleChange} />
                                                        </label>
                                                    </div>
                                                    <Form.Row>
                                                        <Form.Group as={Col} xs={12} >
                                                            <Form.Label htmlFor={'email'}>
                                                                Deine E-Mail
                                                            </Form.Label>
                                                            <Form.Control
                                                                className='input'
                                                                type={'email'}
                                                                name={'email'}
                                                                onChange={handleChange}
                                                                id={'email'}
                                                                required={true}
                                                            />
                                                            <Form.Text className='text-muted'>
                                                                Wir geben deine E-Mail nicht weiter und nutzen deine E-Mail lediglich um
                                                                dich zu benachrichtigen, sobald wir dir das oben beschriebene Angebot
                                                                anbieten können.
                                                            </Form.Text>
                                                            <Form.Control.Feedback type='invalid'>
                                                                Gib bitte deine E-Mail-Adresse an.
                                                            </Form.Control.Feedback>
                                                        </Form.Group>
                                                    </Form.Row>
                                                    <Form.Row>
                                                        <Col>
                                                            <p>
                                                                Mit dem Absenden des Formulars habe ich die Hinweise
                                                                zum <Link to='/datenschutzerklaerung'>Datenschutz</Link> zur
                                                                Kenntnis genommen.
                                                            </p>
                                                            <Button type='submit'>
                                                                Für Benachrichtigung anmelden
                                                            </Button>
                                                        </Col>
                                                    </Form.Row>
                                                </Form>
                                            </React.Fragment>
                                        )}
                                </div>
                            </Jumbotron>
                        </React.Fragment>
                        ) : (
                            <p>Lorem ipsum dolor sed…</p>
                        )}
                </Jumbotron>
                {/* TODO: Remove this code-separation once `settingShopComingSoon` becomes obsolete */}
                {settingShopComingSoon ? (
                    <React.Fragment />
                ) : (
                        <div className='products'>
                            {products.map(({ product }) => (
                                <article key={product.slug} className="product">
                                    <header>
                                        <h3>
                                            <Link to={`/shop/${product.slug}`}>
                                                {product.title}
                                            </Link>
                                        </h3>
                                    </header>
                                    <section>
                                        {product.featuredimage.localFile ? (
                                            <div className="featured-thumbnail">
                                                <PreviewCompatibleImage
                                                    alt={`featured image thumbnail for product ${product.title}`}
                                                    imageInfo={{
                                                        image: product.featuredimage.localFile
                                                    }}
                                                />
                                            </div>
                                        ) : null}
                                        <p
                                            dangerouslySetInnerHTML={{
                                                __html: product.descriptionShort || product.excerpt,
                                            }}
                                        />
                                        <p className="price">
                                            {
                                                new Intl.NumberFormat('de-DE', {
                                                    style: 'currency',
                                                    currency: 'EUR',
                                                }).format((product.priceBrutto / 100).toFixed(2))
                                            }
                                        </p>
                                        <form action="" method="post">
                                            <label htmlFor="quantity">Quantity</label>
                                            <input
                                                type="number"
                                                id="quantity"
                                                name="quantity"
                                                defaultValue="1"
                                                min="1"
                                            />
                                            <input readOnly type="hidden" name="sku" value={product.sku} />
                                            <button type="submit">Buy Now</button>
                                        </form>
                                    </section>
                                </article>
                            ))}
                        </div>
                    )}
            </Container>
        </Layout>
    )
}

export default ShopPage
export const pageQuery = graphql`
  query ShopPageQuery {
    allProduct: allContentfulProduct(
        sort: { fields: [order], order: ASC }
    ) {
        products: edges {
            product: node {
                id
                title
                slug
                sku
                order
                featuredimage {
                    localFile {
                        childImageSharp {
                            fixed(width: 400, height: 400) {
                                ...GatsbyImageSharpFixed
                            }
                        }
                        publicURL
                    }
                }
                priceBrutto
                descriptionShort
                body {
                    childMarkdownRemark {
                        html
                    }
                }
            }
        }
    }
  }
`
